import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	account: Backoffice.populator("account", {
	}),
	building: Backoffice.populator("building", {
	}),
	vlbox: Backoffice.populator("vlbox", {
	}),
};

export default {
	name: "projector",
	collection: "projector",
	idProperty: "_id",
	section: "devices",
	backPopulateList: ["account","building","vlbox"],
	labels: {"singleTitle":"Projector","listTitle":"Projectors"},
	emptyItem: {},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"vlbox": "Vlbox", "projector": "Projector", "case": "Case"}),
			editor: {type: "select", options: [{value: "vlbox", label: "Vlbox"}, {value: "projector", label: "Projector"}, {value: "case", label: "Case"}]},
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "accountId",
			label: "Account Id",
			formatter: Backoffice.formatters.linkObjectId("account", {idProperty: "_id","label": context.account.formatter()}),
			editor: context.account.editor(),
		},
		{
			name: "buildingId",
			label: "Building Id",
			formatter: Backoffice.formatters.linkObjectId("building", {idProperty: "_id","label": context.building.formatter()}),
			editor: context.building.editor(),
		},
		{
			name: "brand",
			label: "Brand",
		},
		{
			name: "model",
			label: "Model",
		},
		{
			name: "picture",
			label: "Picture",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
			noChildren: true,
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "vlboxId",
			label: "Vlbox Id",
			formatter: Backoffice.formatters.linkObjectId("vlbox", {idProperty: "_id","label": context.vlbox.formatter()}),
			editor: context.vlbox.editor(),
		},
		{
			name: "lumens",
			label: "Lumens",
			editor: {type: "float"},
		},
	],
	listColumns: [
		{
			name: "name",
			label: "Name",
		},
		{
			name: "account",
			label: "Account",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "building",
			label: "Building",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "brand",
			label: "Brand",
		},
		{
			name: "model",
			label: "Model",
		},
		{
			name: "vlbox",
			label: "Vlbox",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
	],
	loadSingle() {
		return Promise.all([
			context.account.load(),
			context.building.load(),
			context.vlbox.load(),
		]);
	},
};