import axios from "axios";

export function inviteUser(item)  {
    console.log("User sending to back: ", item)
    return axios.post(process.env.VUE_APP_API_URL + "/invite-user", {user: item})
        .then(res => {
            if (res.data && res.data.success) {
                return {reload: true, message: 'Utilisateur invité ! '}
            }
            throw new Error("Impossible d'inviter l'utilisateur ");
        })
}