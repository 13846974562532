import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	account: Backoffice.populator("account", {
	}),
	building: Backoffice.populator("building", {
	}),
	projector: Backoffice.populator("projector", {
		nullable: true,
	}),
	vlbox: Backoffice.populator("vlbox", {
		nullable: true,
	}),
};

export default {
	name: "vlbox",
	collection: "vlbox",
	idProperty: "_id",
	section: "devices",
	backPopulateList: ["account","building","projector"],
	labels: {"singleTitle":"Vlbox","listTitle":"Vlboxes"},
	emptyItem: {"mode":"video","remoteStatus":{},"warping":{"topLeft":{},"topRight":{},"bottomLeft":{},"bottomRight":{}},"nextCommand":{"parameters":[],"response":null},"lastCommand":{"parameters":[],"response":null}},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"vlbox": "Vlbox", "projector": "Projector", "case": "Case"}),
			editor: {type: "select", options: [{value: "vlbox", label: "Vlbox"}, {value: "projector", label: "Projector"}, {value: "case", label: "Case"}]},
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "accountId",
			label: "Account Id",
			formatter: Backoffice.formatters.linkObjectId("account", {idProperty: "_id","label": context.account.formatter()}),
			editor: context.account.editor(),
		},
		{
			name: "buildingId",
			label: "Building Id",
			formatter: Backoffice.formatters.linkObjectId("building", {idProperty: "_id","label": context.building.formatter()}),
			editor: context.building.editor(),
		},
		{
			name: "brand",
			label: "Brand",
		},
		{
			name: "model",
			label: "Model",
		},
		{
			name: "picture",
			label: "Picture",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
			noChildren: true,
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "projectorId",
			label: "Projector Id",
			formatter: Backoffice.formatters.linkObjectId("projector", {idProperty: "_id","label": context.projector.formatter()}),
			editor: context.projector.editor(),
		},
		{
			name: "mode",
			label: "Mode",
			formatter: Backoffice.formatters.mapping({"video": "Video", "audio": "Audio", "light": "Light", "artnet": "Artnet"}),
			editor: {type: "select", options: [{value: "video", label: "Video"}, {value: "audio", label: "Audio"}, {value: "light", label: "Light"}, {value: "artnet", label: "Artnet"}]},
		},
		{
			name: "ownerBoxId",
			label: "Owner Box Id",
			formatter: Backoffice.formatters.linkObjectId("vlbox", {idProperty: "_id","label": context.vlbox.formatter()}),
			editor: context.vlbox.editor(),
		},
		{
			name: "lightIdentifier",
			label: "Light Identifier",
			formatter: Backoffice.formatters.mapping({"Dina__1A": "Dina 1A", "Dina__2A": "Dina 2A", "Siudi11A": "Siudi11A", "Siudi11B": "Siudi11B", "Siudi11D": "Siudi11D"}),
			editor: {type: "select", options: [{value: "Dina__1A", label: "Dina 1A"}, {value: "Dina__2A", label: "Dina 2A"}, {value: "Siudi11A", label: "Siudi11A"}, {value: "Siudi11B", label: "Siudi11B"}, {value: "Siudi11D", label: "Siudi11D"}]},
		},
		{
			name: "remoteStatus",
			label: "Remote Status",
			readOnly: true,
			children: [
				{
					name: "statusDate",
					label: "Status Date",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
				{
					name: "osMachine",
					label: "Os Machine",
				},
				{
					name: "osPlatform",
					label: "Os Platform",
				},
				{
					name: "osRelease",
					label: "Os Release",
				},
				{
					name: "memoryFree",
					label: "Memory Free",
					editor: {type: "float"},
				},
				{
					name: "memoryTotal",
					label: "Memory Total",
					editor: {type: "float"},
				},
				{
					name: "uptimeSeconds",
					label: "Uptime Seconds",
					editor: {type: "float"},
				},
				{
					name: "storageFree",
					label: "Storage Free",
					editor: {type: "float"},
				},
				{
					name: "storageTotal",
					label: "Storage Total",
					editor: {type: "float"},
				},
			],
		},
		{
			name: "readyScenarios",
			label: "Ready Scenarios",
			readOnly: true,
		},
		{
			name: "warping",
			label: "Warping",
			children: [
				{
					name: "topLeft",
					label: "Top Left",
					children: [
						{
							name: "x",
							label: "X",
							editor: {"type":"integer","min":0},
						},
						{
							name: "y",
							label: "Y",
							editor: {"type":"integer","min":0},
						},
					],
				},
				{
					name: "topRight",
					label: "Top Right",
					children: [
						{
							name: "x",
							label: "X",
							editor: {"type":"integer","min":0},
						},
						{
							name: "y",
							label: "Y",
							editor: {"type":"integer","min":0},
						},
					],
				},
				{
					name: "bottomLeft",
					label: "Bottom Left",
					children: [
						{
							name: "x",
							label: "X",
							editor: {"type":"integer","min":0},
						},
						{
							name: "y",
							label: "Y",
							editor: {"type":"integer","min":0},
						},
					],
				},
				{
					name: "bottomRight",
					label: "Bottom Right",
					children: [
						{
							name: "x",
							label: "X",
							editor: {"type":"integer","min":0},
						},
						{
							name: "y",
							label: "Y",
							editor: {"type":"integer","min":0},
						},
					],
				},
			],
		},
		{
			name: "nextCommand",
			label: "Next Command",
			children: [
				{
					name: "id",
					label: "Id",
				},
				{
					name: "command",
					label: "Command",
				},
				{
					name: "timestamp",
					label: "Timestamp",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
				{
					name: "parameters",
					label: "Parameters",
					list: true,
				},
				{
					name: "status",
					label: "Status",
				},
				{
					name: "response",
					label: "Response",
				},
			],
		},
		{
			name: "lastCommand",
			label: "Last Command",
			children: [
				{
					name: "id",
					label: "Id",
				},
				{
					name: "command",
					label: "Command",
				},
				{
					name: "timestamp",
					label: "Timestamp",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
				{
					name: "parameters",
					label: "Parameters",
					list: true,
				},
				{
					name: "status",
					label: "Status",
				},
				{
					name: "response",
					label: "Response",
				},
			],
		},
	],
	listColumns: [
		{
			name: "name",
			label: "Name",
		},
		{
			name: "account",
			label: "Account",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "building",
			label: "Building",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "brand",
			label: "Brand",
		},
		{
			name: "model",
			label: "Model",
		},
		{
			name: "projector",
			label: "Projector",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "mode",
			label: "Mode",
			formatter: Backoffice.formatters.mapping({"video": "Video", "audio": "Audio", "light": "Light", "artnet": "Artnet"}),
		},
	],
	loadSingle() {
		return Promise.all([
			context.account.load(),
			context.building.load(),
			context.projector.load(),
			context.vlbox.load(),
		]);
	},
};