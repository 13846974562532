import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	account: Backoffice.populator("account", {
	}),
};

export default {
	name: "building",
	collection: "building",
	idProperty: "_id",
	section: "app",
	backPopulateList: ["account"],
	labels: {"singleTitle":"Building","listTitle":"Buildings"},
	emptyItem: {"location":{},"picture":null,"lastCommand":{"parameters":[],"response":null}},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "accountId",
			label: "Account Id",
			formatter: Backoffice.formatters.linkObjectId("account", {idProperty: "_id","label": context.account.formatter()}),
			editor: context.account.editor(),
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "address",
			label: "Address",
		},
		{
			name: "location",
			label: "Location",
			children: [
				{
					name: "lat",
					label: "Lat",
					editor: {type: "float"},
				},
				{
					name: "lng",
					label: "Lng",
					editor: {type: "float"},
				},
			],
		},
		{
			name: "picture",
			label: "Picture",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
			noChildren: true,
		},
		{
			name: "installedAt",
			label: "Installed At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "firstPlay",
			label: "First Play",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "lastPlay",
			label: "Last Play",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "lastStop",
			label: "Last Stop",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "lastScenario",
			label: "Last Scenario",
		},
		{
			name: "currentScenario",
			label: "Current Scenario",
		},
		{
			name: "nextScenario",
			label: "Next Scenario",
		},
		{
			name: "nextPlay",
			label: "Next Play",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "lastCommand",
			label: "Last Command",
			children: [
				{
					name: "id",
					label: "Id",
				},
				{
					name: "command",
					label: "Command",
				},
				{
					name: "timestamp",
					label: "Timestamp",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
				{
					name: "parameters",
					label: "Parameters",
					list: true,
				},
				{
					name: "status",
					label: "Status",
				},
				{
					name: "response",
					label: "Response",
				},
			],
		},
		{
			name: "limitStart",
			label: "Limit Start",
		},
		{
			name: "limitEnd",
			label: "Limit End",
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "account",
			label: "Account",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "address",
			label: "Address",
		},
	],
	loadSingle() {
		return Promise.all([
			context.account.load(),
		]);
	},
};