import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
};

export default {
	name: "log",
	collection: "log",
	idProperty: "_id",
	section: "app",
	labels: {"singleTitle":"Log","listTitle":"Logs"},
	emptyItem: {"value":null,"parameters":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "deviceId",
			label: "Device Id",
		},
		{
			name: "deviceName",
			label: "Device Name",
		},
		{
			name: "deviceType",
			label: "Device Type",
			formatter: Backoffice.formatters.mapping({"vlbox": "Vlbox", "projector": "Projector", "case": "Case"}),
			editor: {type: "select", options: [{value: "vlbox", label: "Vlbox"}, {value: "projector", label: "Projector"}, {value: "case", label: "Case"}]},
		},
		{
			name: "action",
			label: "Action",
		},
		{
			name: "value",
			label: "Value",
		},
		{
			name: "parameters",
			label: "Parameters",
			list: true,
			children: [
				{
					name: "name",
					label: "Name",
				},
				{
					name: "value",
					label: "Value",
				},
			],
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
	],
	loadSingle() {
		return Promise.all([
		]);
	},
};