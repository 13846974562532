import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	building: Backoffice.populator("building", {
	}),
};

export default {
	name: "scenario",
	collection: "scenario",
	idProperty: "_id",
	section: "app",
	backPopulateList: ["building"],
	labels: {"singleTitle":"Scenario","listTitle":"Scenarios"},
	emptyItem: {"type":"simple","picture":null,"files":[],"tracks":[],"boxStates":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "version",
			label: "Version",
			editor: {type: "float"},
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "legend",
			label: "Legend",
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"simple": "Simple", "tracks": "Tracks"}),
			editor: {type: "select", options: [{value: "simple", label: "Simple"}, {value: "tracks", label: "Tracks"}]},
		},
		{
			name: "duration",
			label: "Duration",
		},
		{
			name: "color",
			label: "Color",
		},
		{
			name: "buildingId",
			label: "Building Id",
			formatter: Backoffice.formatters.linkObjectId("building", {idProperty: "_id","label": context.building.formatter()}),
			editor: context.building.editor(),
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"draft": "Draft", "published": "Published", "packaging": "Packaging", "ready": "Ready", "uploading": "Uploading", "available": "Available"}),
			editor: {type: "select", options: [{value: "draft", label: "Draft"}, {value: "published", label: "Published"}, {value: "packaging", label: "Packaging"}, {value: "ready", label: "Ready"}, {value: "uploading", label: "Uploading"}, {value: "available", label: "Available"}]},
		},
		{
			name: "picture",
			label: "Picture",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
			noChildren: true,
		},
		{
			name: "lightDesigner",
			label: "Light Designer",
		},
		{
			name: "firstPlayingAt",
			label: "First Playing At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "broadcastCount",
			label: "Broadcast Count",
			editor: {type: "float"},
		},
		{
			name: "files",
			label: "Files",
			list: true,
			children: [
				{
					name: "vlboxId",
					label: "Vlbox Id",
				},
				{
					name: "video",
					label: "Video",
					children: [
						{
							name: "path",
							label: "Path",
						},
						{
							name: "mimetype",
							label: "Mimetype",
						},
						{
							name: "thumbnail",
							label: "Thumbnail",
						},
						{
							name: "name",
							label: "Name",
						},
					],
				},
				{
					name: "lightIndex",
					label: "Light Index",
					editor: {type: "float"},
				},
			],
		},
		{
			name: "tracks",
			label: "Tracks",
			list: true,
			children: [
				{
					name: "vlboxId",
					label: "Vlbox Id",
				},
				{
					name: "type",
					label: "Type",
					formatter: Backoffice.formatters.mapping({"video": "Video", "audio": "Audio", "light": "Light", "artnet": "Artnet"}),
					editor: {type: "select", options: [{value: "video", label: "Video"}, {value: "audio", label: "Audio"}, {value: "light", label: "Light"}, {value: "artnet", label: "Artnet"}]},
				},
				{
					name: "sequences",
					label: "Sequences",
					list: true,
					children: [
						{
							name: "start",
							label: "Start",
							formatter: Backoffice.formatters.date("DD/MM/YYYY"),
							editor: {type: "date"},
						},
						{
							name: "end",
							label: "End",
							formatter: Backoffice.formatters.date("DD/MM/YYYY"),
							editor: {type: "date"},
						},
						{
							name: "loop",
							label: "Loop",
							formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
							editor: {type: "boolean", labels: ["Non", "Oui"]},
						},
						{
							name: "file",
							label: "File",
							formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
							editor: {
								type: "image",
								accept: "image/png,image/jpeg",
								preview: {
									urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
									contain: true,
									formatUrl: Backoffice.formatters.subProperty('path'),
								}
							},
							noChildren: true,
						},
						{
							name: "lightIndex",
							label: "Light Index",
							editor: {type: "float"},
						},
					],
				},
			],
		},
		{
			name: "boxStates",
			label: "Box States",
			list: true,
			children: [
				{
					name: "vlboxId",
					label: "Vlbox Id",
				},
				{
					name: "state",
					label: "State",
					readOnly: true,
					hideOnCreate: true,
					formatter: Backoffice.formatters.mapping({"waiting": "Waiting", "uploading": "Uploading", "ready": "Ready"}),
				},
			],
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "name",
			label: "Name",
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"simple": "Simple", "tracks": "Tracks"}),
		},
		{
			name: "duration",
			label: "Duration",
		},
		{
			name: "building",
			label: "Building",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"draft": "Draft", "published": "Published", "packaging": "Packaging", "ready": "Ready", "uploading": "Uploading", "available": "Available"}),
		},
	],
	loadSingle() {
		return Promise.all([
			context.building.load(),
		]);
	},
};