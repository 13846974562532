import Backoffice from "@snark/backoffice"
import {inviteUser} from "@/helpers/actions";

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
};

export default {
	name: "user",
	collection: "user",
	idProperty: "_id",
	section: "app",
	viewActions: [
		{
			label: "Inviter",
			cssClass: undefined,
			action: inviteUser,
		},
		{
			type: "logAs",
			logAs: {"field":"email"},
		},
	],
	labels: {"singleTitle":"User","listTitle":"Users"},
	emptyItem: {"picture":{},"resetTokenExpire":null},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "email",
			label: "Email",
		},
		{
			name: "password",
			label: "Password",
			writeOnly: true,
		},
		{
			name: "obsoletePassword",
			label: "Obsolete Password",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created"}),
			editor: {type: "select", options: [{value: "created", label: "Created"}]},
		},
		{
			name: "cguAccepted",
			label: "Cgu Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "marketingAccepted",
			label: "Marketing Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "preferedLanguage",
			label: "Prefered Language",
		},
		{
			name: "picture",
			label: "Picture",
			children: [
				{
					name: "path",
					label: "Path",
				},
				{
					name: "mimetype",
					label: "Mimetype",
				},
				{
					name: "thumbnail",
					label: "Thumbnail",
				},
				{
					name: "name",
					label: "Name",
				},
			],
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "token",
			label: "Token",
		},
		{
			name: "tokenExpire",
			label: "Token Expire",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "resetToken",
			label: "Reset Token",
		},
		{
			name: "resetTokenExpire",
			label: "Reset Token Expire",
		},
	],
	listColumns: [
		{
			name: "email",
			label: "Email",
		},
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created"}),
		},
	],
	loadSingle() {
		return Promise.all([
		]);
	},
};