import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	user: Backoffice.populator("user", {
		displayProperty: item => {
                    return item.firstname + " " + item.lastname;
                },
	}),
};

export default {
	name: "account",
	collection: "account",
	idProperty: "_id",
	section: "app",
	labels: {"singleTitle":"Account","listTitle":"Accounts"},
	emptyItem: {"members":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created"}),
			editor: {type: "select", options: [{value: "created", label: "Created"}]},
		},
		{
			name: "members",
			label: "Members",
			list: true,
			children: [
				{
					name: "userId",
					label: "User Id",
					formatter: Backoffice.formatters.linkObjectId("user", {idProperty: "_id","label": context.user.formatter()}),
					editor: context.user.editor(),
				},
				{
					name: "userEmail",
					label: "User Email",
				},
				{
					name: "invited",
					label: "Invited",
					formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
					editor: {type: "boolean", labels: ["Non", "Oui"]},
				},
				{
					name: "role",
					label: "Role",
					formatter: Backoffice.formatters.mapping({"admin": "Admin", "observer": "Observer"}),
					editor: {type: "select", options: [{value: "admin", label: "Admin"}, {value: "observer", label: "Observer"}]},
				},
				{
					name: "since",
					label: "Since",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
			],
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "name",
			label: "Name",
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	loadSingle() {
		return Promise.all([
			context.user.load(),
		]);
	},
};